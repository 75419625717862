import { zodResolver } from '@hookform/resolvers/zod'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { toast } from 'components/ui/use-toast'
import { deleteImageFromStorage } from 'config/firebase.config'
import { urlConfig } from 'config/url.config'
import { UserRole } from 'modules/user/types/schemas/user.schema'
import React, { forwardRef, memo, useCallback } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useForm } from 'react-hook-form'
import { cn } from 'utils/cn'
import { z } from 'zod'
import { UserType } from '../../user/types/banned-user.type'
import { AdminSchemaType } from '../admin.schema'

interface AdminActionsCellProps extends React.ComponentProps<typeof Button> {
    id: AdminSchemaType['id']
    refetchData?: () => void
    email: AdminSchemaType['email']
    name: AdminSchemaType['name']
    role: UserRole
}
const updateSchema = z.object({
    email: z.string().min(2).email(),
    name: z.string().min(2),
    password: z.string().optional(),
    role: z.nativeEnum(UserRole),
})
export const AdminActionsCell = memo(
    forwardRef<React.ComponentRef<typeof Button>, AdminActionsCellProps>(
        ({ id, refetchData, className, email, name, role, ...props }, ref) => {
            const auth: UserType = useAuthUser()

            const form = useForm<z.infer<typeof updateSchema>>({
                resolver: zodResolver(updateSchema),
                defaultValues: {
                    email: email,
                    name: name,
                    password: '',
                    role: role,
                },
            })

            const onDelete = useCallback<() => void>(async () => {
                const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.adminUsers}/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                if (!resPromise.ok) {
                    toast({
                        title: 'Failed to delete user',
                        description: <>Error occurred while deleting user. Please try again later</>,
                        variant: 'destructive',
                    })
                    return
                }
                const responseData = (await resPromise.json()) as { message: string; data: string; imageURL: string }

                if (responseData.imageURL) {
                    await deleteImageFromStorage(responseData.imageURL)
                }

                toast({
                    title: 'User deleted',
                    description: <>User deleted successfully</>,
                    variant: 'destructive',
                })
                refetchData?.()
            }, [id, refetchData])

            async function onSubmit(values: z.infer<typeof updateSchema>) {
                const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.adminUsers}/${id}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: values.email,
                        name: values.name,
                        role: values.role,
                        ...(values.password && { password: values.password }),
                    }),
                })
                if (!resPromise.ok) {
                    toast({
                        title: 'Failed to update user',
                        description: <>Error occurred while updating user. Please try again later</>,
                        variant: 'destructive',
                    })
                    return
                }

                toast({
                    title: 'User updated',
                    description: <>User updated successfully</>,
                })
                refetchData?.()
            }

            return (
                <div className='flex items-center gap-2'>
                    <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <Button ref={ref} variant='destructive' size='sm' {...props} className={cn('capitalize', className)}>
                                Delete
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone. This will permanently delete account from servers.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction className='bg-destructive hover:bg-destructive/90 w-fit' onClick={onDelete}>
                                    Delete
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                    <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <Button ref={ref} variant='outline' size='sm' {...props} className='capitalize'>
                                Update
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>You can update user email, name and password</AlertDialogTitle>
                            </AlertDialogHeader>
                            <Form {...form}>
                                <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-2'>
                                    <FormField
                                        control={form.control}
                                        name='email'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Email</FormLabel>
                                                <FormControl>
                                                    <Input placeholder='someemail@example.com' {...field}/>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name='name'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Name</FormLabel>
                                                <FormControl>
                                                    <Input placeholder='John Doe' {...field}/>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name='password'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Password(optional)</FormLabel>
                                                <FormControl>
                                                    <Input placeholder='*****' {...field}/>
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name='role'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Role</FormLabel>
                                                <Select
                                                    disabled={auth?.role !== UserRole.SUPERADMIN && role === UserRole.SUPERADMIN}
                                                    onValueChange={field.onChange}
                                                    defaultValue={field.value}
                                                    required
                                                >
                                                    <FormControl>
                                                        <SelectTrigger>
                                                            <SelectValue defaultValue={UserRole.USER} className='capitalize'/>
                                                        </SelectTrigger>
                                                    </FormControl>
                                                    <SelectContent>
                                                        {Object.values(UserRole).map(role => {
                                                            if (role === UserRole.SUPERADMIN || role === UserRole.ADMIN) {
                                                                return (
                                                                    <SelectItem
                                                                        disabled={auth?.role !== UserRole.SUPERADMIN}
                                                                        key={role}
                                                                        value={role}
                                                                        className='capitalize'
                                                                    >
                                                                        {role}
                                                                    </SelectItem>
                                                                )
                                                            }
                                                            return (
                                                                <SelectItem key={role} value={role} className='capitalize'>
                                                                    {role}
                                                                </SelectItem>
                                                            )
                                                        })}
                                                    </SelectContent>
                                                </Select>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                    <div className='flex justify-end gap-2'>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction type='submit'>Submit</AlertDialogAction>
                                    </div>
                                </form>
                            </Form>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            )
        },
    ),
)
AdminActionsCell.displayName = 'ActionCell'
