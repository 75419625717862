import { zodResolver } from '@hookform/resolvers/zod'
import { useLogin } from 'auth/use-login'
import FlagIcon from 'components/flag-icon'
import { LoadingIcon } from 'components/loading-icon'
import { PageTitle } from 'components/page-title'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'
import { pageTransitionClassName } from 'config/constants'
import { getCurrentISODate } from 'config/dayjs.config'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import { ArrowLeft } from 'lucide-react'
import CreateInstance from 'modules/instances/components/create-instance'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { ManageUsersCreateForm } from 'modules/user/components/manage-users-create-form'
import { NewUserSchema, NewUserSchemaType, UserRole } from 'modules/user/types/schemas/user.schema'
import { getUserIp } from 'modules/user/utils/get-user-ip'
import { getUserLocation } from 'modules/user/utils/get-user-location'
import { memo, useCallback, useEffect, useState } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'
import { userRepo } from '../config/firebase.config'
import { UserType } from '../modules/user/types/banned-user.type'

interface MainPageProps {
    className?: string
}
export const MainPage: React.FC<MainPageProps> = memo(({ className, ...props }) => {
    const [disabled, setDisabled] = useState<boolean>(false)
    const [step, setStep] = useState<string>('')

    const instances = useInstanceStore(state => state.instances)
    const loading = useInstanceStore(state => state.isAllLoading)
    const auth: UserType = useAuthUser()

    const { registerWithEmailAndPassword } = useLogin()

    const [instanceLength, setInstanceLength] = useState<number>(instances.length)

    useEffect(() => {
        setInstanceLength(instances.length)
    }, [instances])

    const navigate = useNavigate()

    const form = useForm<NewUserSchemaType>({
        resolver: zodResolver(NewUserSchema),
        defaultValues: {
            email: '',
            name: '',
            role: UserRole.USER,
        },
    })

    const onSubmit = useCallback<(values: NewUserSchemaType) => Promise<void>>(
        async values => {
            const ip = await getUserIp()
            const userLocation = await getUserLocation(ip)
            setDisabled(true)
            try {
                const uid = await registerWithEmailAndPassword(
                    values.email,
                    values.email,
                    values.name,
                )
                await userRepo.create(uid, {
                    name: values.name,
                    email: values.email,
                    role: values.role,
                    userOwnerId: auth?.userOwnerId,
                    registered_ip: ip,
                    registered_date: getCurrentISODate(),
                    registered_location: JSON.stringify(userLocation),
                })
                navigate(urlConfig.pages.admin)
                toastConfig.user.create.success(values.email)
                form.reset()
            } catch (error) {
                console.error(error)
                toastConfig.user.create.error()
            } finally {
                setDisabled(false)
            }
        },
        [auth?.userOwnerId, form, navigate, registerWithEmailAndPassword],
    )

    const MainPageCardContent = ({ step }: { step: string }) => {
        switch (step) {
            case 'project':
                return (
                    <>
                        <p className='text-center font-serif text-3xl'>Add new project</p>
                        <p className='text-center'>Create the project for the website where the Vista will be added</p>
                        <CreateInstance/>
                    </>
                )
            case 'user':
                return (
                    <>
                        <p className='text-center font-serif text-3xl'>Add new users</p>
                        <p className='text-center'>Create users which will be able to use the website</p>
                        <ManageUsersCreateForm
                            userRole={auth?.role}
                            form={form}
                            onSubmit={onSubmit}
                            isButtonDisabled={disabled}
                            className='w-full'
                        />
                    </>
                )
            default:
                return (
                    <>
                        <FlagIcon/>
                        <p className='text-center font-serif text-3xl'>Let's get started</p>
                        <p className='text-center'>You can start by creating the new project or by adding the users</p>
                        <div className='flex flex-col items-center gap-2'>
                            <Button
                                className={cn(
                                    'my-1 w-fit rounded-full border-2 border-zinc-900 bg-transparent text-xl font-bold uppercase',
                                )}
                                variant='secondary'
                                onClick={() => setStep('project')}
                            >
                                Add new website
                            </Button>
                            <Button
                                className={cn(
                                    'my-1 w-fit rounded-full border-2 border-zinc-900 bg-transparent text-xl font-bold uppercase',
                                )}
                                variant='secondary'
                                onClick={() => setStep('user')}
                            >
                                Add user
                            </Button>
                        </div>
                    </>
                )
        }
    }
    if (loading) {
        return (
            <div className='flex h-96 w-screen items-center justify-center'>
                <LoadingIcon loading={loading} className='mr-2'/>
            </div>
        )
    }
    return (
        <>
            <PageTitle/>
            <div className={cn('mt-6 flex w-full flex-col items-center justify-center', pageTransitionClassName)}>
                {(auth?.role === UserRole.SUPERADMIN || auth?.role === UserRole.ADMIN) && instances.length === 0 && (
                    <Card className='p-3 md:w-1/3 '>
                        {step && (
                            <Button
                                variant='ghost'
                                className={cn('flex w-full items-center gap-2')}
                                onClick={() => setStep('')}
                            >
                                <ArrowLeft/>
                                Back
                            </Button>
                        )}
                        <CardContent className='mt-6 flex flex-col items-center gap-2'>
                            <MainPageCardContent step={step}/>
                        </CardContent>
                    </Card>
                )}
            </div>
        </>
    )
})
MainPage.displayName = MainPage.name
