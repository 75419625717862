import { Table } from '@tanstack/react-table'
import { Input } from 'components/ui/input'
import { cn } from 'utils/cn'

export type PropsWithoutChildren<P> = Omit<P, 'children'>

interface DataTableInputFilterProps<TData> extends React.ComponentProps<typeof Input> {
    table?: Table<TData>
    //
    column: string
    label?: string
}
export const DataTableInputFilter = <TData,>({
    table,
    column,
    label = column,
    className,
    ...props
}: PropsWithoutChildren<DataTableInputFilterProps<TData>>) => {
    if (!table) {
        return null
    }

    const value = (table.getColumn(column)?.getFilterValue() as string) ?? ''

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        table.getColumn(column)?.setFilterValue(event.target.value)
    }

    return (
        <Input
            key={column}
            placeholder={`Filter by ${label}...`}
            value={value}
            onChange={onChange}
            className={cn('h-9 max-w-[230px]', className)}
            {...props}
        />
    )
}
