import { memo } from 'react'
import AuthKitProvider from 'react-auth-kit'
import createStore from 'react-auth-kit/createStore'

const store = createStore({
    authName : '_auth',
    authType : 'cookie',
    cookieDomain : window.location.hostname,
    cookieSecure : window.location.protocol === 'https:',
})

export enum AUTH_LOCAL_STORAGE_KEY {
    AUTH = '_auth',
    GOOGLE_PASSWORDLESS = '_auth/google_passwordless',
}

interface AuthProviderProps {}
export const AuthProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = memo(({ children }) => {
    return (
        <AuthKitProvider store={store}>
            {children}
        </AuthKitProvider>
    )
})
AuthProvider.displayName = AuthProvider.name