import { LoadingIcon } from 'components/loading-icon'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { dotenv, ENV } from 'config/dotenv.config'
import { firebaseCollections, firebaseFirestore } from 'config/firebase.config'
import { urlConfig } from 'config/url.config'
import dayjs from 'dayjs'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { Ellipsis } from 'lucide-react'
import { CustomizeChatIcon } from 'modules/chat/components/customize-chat-icon'
import CustomizeClientChatLayout from 'modules/chat/components/customize-client-chat-layout'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType, ChatTypeFieldsEnum } from 'modules/chat/types/chat.type'
import { UserRole } from 'modules/user/types/schemas/user.schema'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useNavigate, useParams } from 'react-router-dom'
import { cn } from 'utils/cn'
import { UserType } from '../../user/types/banned-user.type'
import InstanceChats from '../components/instance-chats'
import InstanceCodeSnippet from '../components/instance-code-snippet'
import { InstanceDeleteSlotItem } from '../components/instance-delete-slot-item'
import { InstanceUpdateSlotItem } from '../components/instance-update-slot-item'
import { useInstanceStore } from '../store/instances.store'
import { InstanceType } from '../types/instance.type'

const Instance = ({ step, setStep }) => {
    const { id } = useParams()

    const navigate = useNavigate()

    if (!id) navigate(urlConfig.pages.main)

    const auth: UserType = useAuthUser()

    const [fetchedInstance, setFetchedInstance] = useState<InstanceType | undefined>()

    const fetchInstanceById = useInstanceStore(state => state.fetchInstanceById)
    const isIdLoading = useInstanceStore(state => state.isIdLoading)

    const root = useMemo(() => document.getElementById('root'), [])

    const chats = useChatStore(state => state.chats)
    const archivedChats = useChatStore(state => state.archivedChats)
    const bannedChats = useChatStore(state => state.bannedChats)
    const isAllLoading = useChatStore(state => state.isAllLoading)
    const fetchChats = useChatStore(state => state.fetchChats)

    const scriptUrl = useMemo(() => {
        const url = new URL(`${dotenv.get(ENV.API_URL).replace('api', 'widgets')}/${id}`)
        return url.toString()
    }, [id])

    const sortChatsByLastMessage = useCallback<(a: ChatType, b: ChatType) => number>((a, b) => {
        if (!a.messages?.length) return -1
        if (!b.messages?.length) return 1
        const dateA = dayjs(a.messages?.at(-1)?.createdAt)
        const dateB = dayjs(b.messages?.at(-1)?.createdAt)
        return dateB.diff(dateA)
    }, [])

    useEffect(() => {
        if (root) {
            root.style.setProperty('--message', '221.2 83.2% 53.3%')
            root.style.setProperty('--chat-header', '0 0% 100%')
            root.style.setProperty('--chat-footer', '0 0% 100%')
            root.style.setProperty('--chat-content', '0, 0%, 100%, 0')
        }
    }, [root])

    const refetchInstance = () => {
        fetchInstanceById(id as string).then(instance => {
            setFetchedInstance(instance)
        })
    }

    const InstanceCardContent = ({ step }: { step: string }) => {
        switch (step) {
            case 'styling':
                return <CustomizeClientChatLayout
                    refetchInstance={() => refetchInstance()}
                    instance={fetchedInstance as InstanceType}
                    step={step}
                />
            case 'icon':
                return <CustomizeChatIcon
                    refetchInstance={() => refetchInstance()}
                    instance={fetchedInstance as InstanceType}
                />
            case 'chats':
                return <InstanceChats
                    instance={fetchedInstance as InstanceType}
                    chats={chats}
                    archivedChats={archivedChats}
                    bannedChats={bannedChats}
                    sortChatsByLastMessage={sortChatsByLastMessage}
                />
            case 'snippet':
                return <InstanceCodeSnippet scriptUrl={scriptUrl}/>
        }
    }

    useEffect(() => {
        fetchInstanceById(id as string).then(instance => {
            setFetchedInstance(instance)
        })
    }, [id, fetchInstanceById])

    useEffect(() => {
        if (id) {
            const q = query(
                collection(firebaseFirestore, firebaseCollections.chats),
                where(ChatTypeFieldsEnum.INSTANCE_ID, '==', id),
            )
            const unsub = onSnapshot(q, snapshot => {
                const chats = snapshot.docs.map(
                    doc => ({
                        ...doc.data(),
                        id: doc.id,
                    } as ChatType),
                )
                fetchChats(id)
            })
            return () => unsub()
        }
    }, [fetchChats, id])

    if (isIdLoading || isAllLoading) {
        return (
            <div className='flex h-96 w-full items-center justify-center'>
                <LoadingIcon loading={isIdLoading || isAllLoading} className='mr-2'/>
            </div>
        )
    }

    return (
        <div className='w-full'>
            <Card className='w-full border-none '>
                <CardHeader className={cn('p-0', 'border-b-')}>
                    <div className='flex items-center justify-between px-7 pt-3'>
                        <p className=' font-serif text-3xl'>{fetchedInstance?.name}</p>

                        <Popover>
                            <PopoverTrigger>
                                <Ellipsis/>
                            </PopoverTrigger>
                            <PopoverContent className='w-40 p-1 text-left'>
                                <InstanceUpdateSlotItem instance={fetchedInstance as InstanceType}/>
                                <InstanceDeleteSlotItem instanceId={id}/>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className='relative flex gap-4 px-7'>
                        <p
                            onClick={() => {
                                setStep('chats')
                                if (root) {
                                    root.style.setProperty('--message', '221.2 83.2% 53.3%')
                                    root.style.setProperty('--chat-header', '0 0% 100%')
                                    root.style.setProperty('--chat-footer', '0 0% 100%')
                                    root.style.setProperty('--chat-content', '0, 0%, 100%, 0')
                                }
                            }}
                            className={cn(
                                'z-10 cursor-pointer',
                                'transition-all duration-150 ease-in-out',
                                'hover:border-b-2 hover:border-blue-600 hover:text-blue-600',
                                step === 'chats' && 'border-b-2 border-blue-600 text-blue-600',
                            )}
                        >
                            Chats
                        </p>
                        {(auth?.role === UserRole.ADMIN || auth?.role === UserRole.SUPERADMIN) && (
                            <>
                                <p
                                    onClick={() => setStep('styling')}
                                    className={cn(
                                        'z-10 cursor-pointer',
                                        'transition-all duration-150 ease-in-out',
                                        'hover:border-b-2 hover:border-blue-600 hover:text-blue-600',
                                        step === 'styling' && 'border-b-2 border-blue-600 text-blue-600',
                                    )}
                                >
                                    Styling
                                </p>
                                <p
                                    onClick={() => {
                                        setStep('icon')
                                        if (root) {
                                            root.style.setProperty('--message', '221.2 83.2% 53.3%')
                                            root.style.setProperty('--chat-header', '0 0% 100%')
                                            root.style.setProperty('--chat-footer', '0 0% 100%')
                                            root.style.setProperty('--chat-content', '0, 0%, 100%, 0')
                                        }
                                    }}
                                    className={cn(
                                        'z-10 cursor-pointer',
                                        'transition-all duration-150 ease-in-out',
                                        'hover:border-b-2 hover:border-blue-600 hover:text-blue-600',
                                        step === 'icon' && 'border-b-2 border-blue-600 text-blue-600',
                                    )}
                                >
                                    Icon
                                </p>
                                <p
                                    onClick={() => {
                                        setStep('snippet')
                                        if (root) {
                                            root.style.setProperty('--message', '221.2 83.2% 53.3%')
                                            root.style.setProperty('--chat-header', '0 0% 100%')
                                            root.style.setProperty('--chat-footer', '0 0% 100%')
                                            root.style.setProperty('--chat-content', '0, 0%, 100%, 0')
                                        }
                                    }}
                                    className={cn(
                                        'z-10 cursor-pointer',
                                        'transition-all duration-150 ease-in-out',
                                        'hover:border-b-2 hover:border-blue-600 hover:text-blue-600',
                                        step === 'snippet' && 'border-b-2 border-blue-600 text-blue-600',
                                    )}
                                >
                                    Code snippet
                                </p>
                            </>
                        )}
                        <div className='absolute bottom-0 right-0 z-0 w-full border-b-2'></div>
                    </div>
                </CardHeader>
                <CardContent className='w-full px-7 py-3'>
                    <InstanceCardContent step={step}/>
                </CardContent>
            </Card>
        </div>
    )
}
export default Instance