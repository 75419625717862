import { urlConfig } from 'config/url.config'
import { useEffect, useState } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { UserType } from '../modules/user/types/banned-user.type'
import { UserRole } from '../modules/user/types/schemas/user.schema'

export const useSites = () => {
    const [sites, setData] = useState([])
    const url = `${urlConfig.API_URL}${urlConfig.api.sites}`
    const auth: UserType = useAuthUser()

    const fetchData = async () => {
        const data = await fetch(url).then(r => r.json()).catch(console.error)
        setData(data)
    }

    useEffect(() => {
        auth?.role === UserRole.SUPERADMIN && fetchData()
    }, [])

    const refetchSites = () => fetchData()

    return { sites, refetchSites }
}