import { AUTH_LOCAL_STORAGE_KEY } from 'auth/auth-provider'
import { OnSignInWithGoogle } from 'auth/sign-in-with-google'
import { dbUsers, firebaseAuth } from 'config/firebase.config'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import {
    createUserWithEmailAndPassword,
    isSignInWithEmailLink,
    sendPasswordResetEmail,
    sendSignInLinkToEmail,
    signInWithEmailAndPassword,
    updateProfile,
} from 'firebase/auth'
import { where } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useNavigate } from 'react-router-dom'
import { UserRole } from '../modules/user/types/schemas/user.schema'

type AuthState = Parameters<OnSignInWithGoogle>[0]

export const useLogin = () => {
    const signIn = useSignIn()
    const navigate = useNavigate()
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    const login = useCallback<(params: { authState: AuthState; callbackUrl: string }) => Promise<void>>(
        async ({ authState, callbackUrl }) => {
            try {
                if (!authState.name || !authState.email) {
                    throw new Error('Invalid auth state. Name and email are required')
                }

                setIsButtonDisabled(true)

                const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.login}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: authState.email,
                    }),
                })
                if (!resPromise.ok) {
                    throw new Error('Response error')
                }

                const res = (await resPromise.json()) as {
                    message: string
                    token: string
                    role: UserRole
                    expiresIn: number
                    id: string
                    userOwnerId: string | undefined
                    image: string | undefined
                    emailVerified: boolean
                }
                if (!res.token) {
                    throw new Error('Invalid response data')
                }

                signIn({
                    auth: {
                        token: res.token,
                        type: 'Bearer',
                    },
                    userState: {
                        ...authState,
                        emailVerified: res.emailVerified,
                        id: res.id,
                        image: res.image,
                        role: res.role,
                        userOwnerId: res.userOwnerId === 'owner' ? res.id : res.userOwnerId,
                    },
                })
                toastConfig.login.success(authState.name)
                navigate(callbackUrl)
            } catch (error) {
                console.error(error)
                toastConfig.login.error()
            } finally {
                setIsButtonDisabled(false)
            }
        },
        [navigate, signIn],
    )

    const signInWithGoogle = useCallback<OnSignInWithGoogle>(
        async user => {
            await login({
                authState: {
                    name: 'Unknown user',
                    ...user,
                },
                callbackUrl: urlConfig.pages.main,
            })
        },
        [login],
    )

    const signInWithEmailLink = useCallback<(email: string, cb?: () => void | Promise<void>) => Promise<void>>(
        async (email, cb) => {
            try {
                await sendSignInLinkToEmail(firebaseAuth, email, {
                    url: window.location.href,
                    handleCodeInApp: true,
                })
                localStorage.setItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS, email)
                cb?.()
                toastConfig.login.successWithEmail(email)
            } catch (error) {
                toastConfig.login.error()
            }
        },
    [],
    )

    const loginWithEmailAndPassword = useCallback<
        (email: string, password: string, cb?: () => void | Promise<void>) => Promise<void>
    >(
            async (email, password, cb) => {
                try {
                    setIsButtonDisabled(true)
                    await signInWithEmailAndPassword(firebaseAuth, email, password)
                        .then(async userCredential => {
                            const user = userCredential.user

                            await login({
                                authState: {
                                    name: user.displayName ?? undefined,
                                    email: user.email ?? undefined,
                                },
                                callbackUrl: urlConfig.pages.main,
                            }).then(() => {
                                toastConfig.login.success(user.displayName ?? 'Unknown user')
                                setIsButtonDisabled(false)
                            })
                        })
                        .catch(error => {
                            toastConfig.login.error()
                            setIsButtonDisabled(false)
                        })
                    cb?.()
                } catch (error) {
                    toastConfig.login.error()
                    setIsButtonDisabled(false)
                }
            },
            [login],
            )

    const registerWithEmailAndPassword = useCallback<
        (email: string, password: string, name: string, cb?: () => void | Promise<void>) => Promise<string>
    >(async (email, password, name) => {
                try {
                    setIsButtonDisabled(true)

                    const firestoreDatabaseUser = await dbUsers.query(where('email', '==', email))

                    if (firestoreDatabaseUser.length > 0) {
                        toastConfig.register.error()
                        setIsButtonDisabled(false)
                        return
                    }

                    const superadmins = await dbUsers.query(where('role', '==', UserRole.SUPERADMIN))

                    for (let i = 0; i < superadmins.length; i++) {
                        const resPromise = await fetch(`${urlConfig.API_URL}${urlConfig.api.newUserReg}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                to: superadmins?.[i]?.email,
                                userEmail: email,
                            }),
                        })
                        if (!resPromise.ok) {
                            throw new Error('Response error')
                        }
                    }

                    return await createUserWithEmailAndPassword(firebaseAuth, email, password)
                        .then(async userCredential => {
                            const user = userCredential.user

                            updateProfile(user, { displayName: name })
                                .then()
                                .catch(error => {
                                    console.error('Error updating profile:', error.message)
                                })

                            return user.uid
                        })
                        .catch(error => {
                            toastConfig.register.error()
                            throw new Error(error.message)
                        })
                } catch (error) {
                    toastConfig.register.error()
                    setIsButtonDisabled(false)
                }
            }, [])

    const resetUserPassword = useCallback<(email: string, cb?: () => void | Promise<void>) => Promise<void>>(
        async (email, cb) => {
            try {
                setIsButtonDisabled(true)
                await sendPasswordResetEmail(firebaseAuth, email)
                    .then(res => {
                        cb?.()
                        toastConfig.reset.resetWithEmail(email)
                        setIsButtonDisabled(false)
                    })
                    .catch(error => {
                        const errorCode = error.code
                        const errorMessage = error.message
                        toastConfig.reset.error()
                        setIsButtonDisabled(false)
                    })
            } catch (error) {
                toastConfig.reset.error()
                setIsButtonDisabled(false)
            }
        },
    [],
    )

    // sign in with google email passwordless link
    useEffect(() => {
        try {
            if (!isSignInWithEmailLink(firebaseAuth, window.location.href)) {
                return
            }
            const email = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS)
            window.localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY.GOOGLE_PASSWORDLESS)
            if (!email) {
                return
            }
            login({
                authState: {
                    email,
                    name: email.split('@')[0],
                },
                callbackUrl: urlConfig.pages.main,
            })
        } catch (error) {
            toastConfig.login.error()
        }
    }, [login])

    return {
        isButtonDisabled,
        login,
        signInWithGoogle,
        signInWithEmailLink,
        loginWithEmailAndPassword,
        registerWithEmailAndPassword,
        resetUserPassword,
    } as const
}