interface MapConfig {
    defaultLat: number
    defaultLng: number
    defaultZoom: number
    maxZoom: number
    projection
    initialMapStyles: React.CSSProperties
    defaultViewport
}
export const mapConfig: MapConfig = {
    defaultLat: 0,
    defaultLng: 0,
    defaultZoom: 13,
    maxZoom: 20,
    projection: {
        name: 'globe',
    },
    initialMapStyles: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    defaultViewport: {
        latitude: 0,
        longitude: 0,
        zoom: 11,
        bearing: 0,
        pitch: 0,
        padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
}
