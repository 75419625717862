import { zodResolver } from '@hookform/resolvers/zod'
import { TabsContent } from '@radix-ui/react-tabs'
import { useLogin } from 'auth/use-login'
import { LoadingIcon } from 'components/loading-icon'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableFacetedFilter } from 'components/ui/data-table-faceted-filter'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'
import { getCurrentISODate } from 'config/dayjs.config'
import { toastConfig } from 'config/toast.config'
import { adminUsersColumns } from 'modules/admin/admin-columns'
import { useGetAdminUsers } from 'modules/admin/hooks/use-admin'
import { ManageUsersCreateForm } from 'modules/user/components/manage-users-create-form'
import { NewUserSchema, NewUserSchemaType, UserRole } from 'modules/user/types/schemas/user.schema'
import { getUserIp } from 'modules/user/utils/get-user-ip'
import { getUserLocation } from 'modules/user/utils/get-user-location'
import { useCallback, useState } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useForm } from 'react-hook-form'
import { userRepo } from '../config/firebase.config'
import { UserType } from '../modules/user/types/banned-user.type'
import { siteColumns } from '../sites/columns'
import { useSites } from '../sites/use-admin'

const Admin = () => {
    const auth: UserType = useAuthUser()

    const [disabled, setDisabled] = useState<boolean>(false)

    const { registerWithEmailAndPassword } = useLogin()
    const { data, loading, refetchData } = useGetAdminUsers(auth?.id)
    const { sites, refetchSites } = useSites()

    const form = useForm<NewUserSchemaType>({
        resolver: zodResolver(NewUserSchema),
        defaultValues: {
            email: '',
            name: '',
            role: UserRole.USER,
        },
    })

    const onSubmit = useCallback<(values: NewUserSchemaType) => Promise<void>>(
        async values => {
            try {
                setDisabled(true)
                const ip = await getUserIp()
                const userLocation = await getUserLocation(ip)
                const uid = await registerWithEmailAndPassword(
                    values.email,
                    values.email,
                    values.name,
                )
                await userRepo.create(uid, {
                    name: values.name,
                    email: values.email,
                    role: values.role,
                    userOwnerId: auth?.userOwnerId,
                    registered_ip: ip,
                    registered_date: getCurrentISODate(),
                    registered_location: JSON.stringify(userLocation),
                })
                toastConfig.user.create.success(values.email)
                refetchData()
                form.reset()
            } catch (error) {
                console.error(error)
                toastConfig.user.create.error()
            } finally {
                setDisabled(false)
            }
        },
        [auth?.userOwnerId, form, refetchData, registerWithEmailAndPassword],
    )

    if (loading) {
        return (
            <div className='flex h-96 w-screen items-center justify-center'>
                <LoadingIcon loading={loading} className='mr-2'/>
            </div>
        )
    }
    return (
        <div className='px-10'>
            <Card className='py-3'>
                <div className='animate-in fade-in-0 container space-y-5 transition-all duration-300'>
                    <Tabs defaultValue='table' className='flex w-full flex-col items-center gap-3'>
                        <TabsList className='w-fit'>
                            <TabsTrigger value='table'>Users</TabsTrigger>
                            <TabsTrigger value='create'>Add user</TabsTrigger>
                            {auth?.role === UserRole.SUPERADMIN && (
                                <TabsTrigger value='sites'>Sites</TabsTrigger>
                            )}
                        </TabsList>

                        <TabsContent value='table' className='w-full pb-3'>
                            <DataTable
                                columns={adminUsersColumns}
                                data={data?.map(user => ({ ...user, refetch: refetchData })) ?? []}
                                pagination
                                initialHidden={['uid']}
                                className='w-full overflow-x-auto'
                            >
                                <DataTableToolbar>
                                    <DataTableInputFilter column='name' label='Name'/>
                                    <DataTableInputFilter column='email' label='Email'/>
                                    <DataTableInputFilter column='createdBy' label='Created by'/>
                                    <DataTableFacetedFilter
                                        column='role'
                                        options={Object.values(UserRole).map(role => ({ data: role }))}
                                    />
                                </DataTableToolbar>
                            </DataTable>
                        </TabsContent>
                        <TabsContent value='create'>
                            <Card className='border-none shadow-none'>
                                <CardHeader>
                                    <CardTitle>Create new User</CardTitle>
                                    <CardDescription>Add email, name, select role and that's all!</CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <ManageUsersCreateForm
                                        userRole={auth?.role}
                                        form={form}
                                        onSubmit={onSubmit}
                                        isButtonDisabled={disabled}
                                    />
                                </CardContent>
                            </Card>
                        </TabsContent>
                        {auth?.role === UserRole.SUPERADMIN && (
                            <TabsContent value='sites'>
                                <DataTable
                                    // @ts-ignore
                                    columns={siteColumns}
                                    data={sites?.map(site => ({ ...site, refetch: refetchSites })) ?? []}
                                ></DataTable>
                            </TabsContent>
                        )}
                    </Tabs>
                </div>
            </Card>
        </div>
    )
}
export default Admin
