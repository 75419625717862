import { getCurrentISODate } from 'config/dayjs.config'
import { dotenv, ENV } from 'config/dotenv.config'
import { firebaseAuth, firebaseProvider } from 'config/firebase.config'
import {
    getAdditionalUserInfo,
    GoogleAuthProvider,
    signInWithPopup
} from 'firebase/auth'
import { UserRole } from 'modules/user/types/schemas/user.schema'
import { getUserIp } from 'modules/user/utils/get-user-ip'
import { getUserLocation } from 'modules/user/utils/get-user-location'

const api = { uri: dotenv.get(ENV.API_URL) }

const auth = {
    google: ({ idToken, newUser }) => fetch(`${api.uri}/auth/google`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idToken, newUser })
    })
}
const users = {
    exists: (id) => fetch(`${api.uri}/user/${id}`).then(res => res.json())
}

export const continueWithGoogle = async () => {
    const result = await signInWithPopup(firebaseAuth, firebaseProvider)
    const { idToken } = GoogleAuthProvider.credentialFromResult(result)
    const { uid } = result.user
    const {
        isNewUser = !await users.exists(uid),
        profile: { email, name }
    } = getAdditionalUserInfo(result)
    let newUser
    if (isNewUser) {
        const ip = await getUserIp()
        const location = await getUserLocation(ip)
        newUser = {
            email,
            id: uid,
            name,
            registered_date: getCurrentISODate(),
            registered_ip: ip,
            registered_location: JSON.stringify(location),
            role: UserRole.ADMIN,
            userOwnerId: 'owner',
        }
    }
    const res = await auth.google({ idToken, newUser })
    if (res.ok) return { email, name }
}