import { LoadingButton } from 'components/loading-button'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogHeader,
    AlertDialogTitle
} from 'components/ui/alert-dialog'
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from 'components/ui/form'
import { Input } from 'components/ui/input'
import { toastConfig } from 'config/toast.config'
import { useGetUsernameForm } from 'modules/user/hooks/use-get-username-form'
import { GetUsernameFormSchemaType } from 'modules/user/types/schemas/get-username-form.schema'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { cn } from 'utils/cn'

interface GetUsernameModalProps {
    initialOpen: boolean
    onSubmit: (username: GetUsernameFormSchemaType) => void | Promise<void>
    textColor?: string
}
export const GetUsernameModal: React.FC<GetUsernameModalProps> = memo(({ initialOpen, onSubmit, textColor }) => {
    const form = useGetUsernameForm()

    const [open, setOpen] = useState<boolean>(initialOpen)
    const [loading, setLoading] = useState<boolean>(false)

    const inputRef = useRef<React.ComponentRef<typeof Input> | null>(null)

    const handleSubmit = useCallback(
        async (values: GetUsernameFormSchemaType) => {
            try {
                setLoading(true)
                await onSubmit(values)
                setOpen(false)
            } catch (error) {
                console.error(error)
                toastConfig.unknownError()
            } finally {
                setLoading(false)
            }
        },
        [onSubmit],
    )

    // focus input on open
    useEffect(() => {
        if (open) {
            setTimeout(() => {
                inputRef.current?.focus()
            }, 50)
        }
    }, [open])

    return (
        <AlertDialog open={open}>
            <AlertDialogContent className={cn('bg-chatcontent', textColor)}>
                <AlertDialogHeader>
                    <AlertDialogTitle>Enter your name</AlertDialogTitle>
                    <AlertDialogDescription className={cn('text-sm', textColor)}>To start chatting</AlertDialogDescription>
                </AlertDialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} className='space-y-6'>
                        <FormField
                            name='username'
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            ref={inputRef}
                                            type='text'
                                            autoComplete='off'
                                            placeholder='John Doe'
                                            disabled={loading}
                                            className={cn(
                                                textColor === 'text-black' ? 'border-black' : 'border-white',
                                                textColor,
                                                textColor === 'text-black' ? `placeholder:text-black` : 'placeholder:text-white',
                                            )}
                                        />
                                    </FormControl>
                                    <FormDescription className={cn('text-sm', textColor)}>
                                        This name will be visible to the administrator.
                                    </FormDescription>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <div className='flex w-full justify-end'>
                            <LoadingButton type='submit' loading={loading}>
                                Submit
                            </LoadingButton>
                        </div>
                    </form>
                </Form>
            </AlertDialogContent>
        </AlertDialog>
    )
})
GetUsernameModal.displayName = GetUsernameModal.name
