import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { useState } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { UserType } from '../../user/types/banned-user.type'
import { useInstanceStore } from '../store/instances.store'

const createInstanceSchema = z.object({
    name: z.string().min(3, {
        message: 'Name must be at least 3 characters long',
    }),
})

const CreateInstance = () => {
    const auth: UserType = useAuthUser()
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    const createInstance = useInstanceStore(state => state.createInstance)
    const updateInstance = useInstanceStore(state => state.updateInstance)

    const createInstanceForm = useForm<z.infer<typeof createInstanceSchema>>({
        resolver: zodResolver(createInstanceSchema),
        defaultValues: {
            name: '',
        },
    })

    async function onSubmit(values: z.infer<typeof createInstanceSchema>) {
        setIsButtonDisabled(true)
        const newInstance = await createInstance()

        await updateInstance({
            ...newInstance,
            name: values.name,
            ownerId: auth?.userOwnerId,
            colorSettings: JSON.stringify({
                contentBg: '#ffffff',
                headerCardBg: '#000000',
                footerCardBg: '#000000',
                clientMesBg: '#000000',
            }),

            iconSettings: JSON.stringify({
                iconRadius: 'square',
                iconBgColorStyle: 'gradient',
                iconBg: '#1424ff',
                iconBgSecond: '#000000',
                iconSize: '65',
                iconPosition: 'right',
                iconPositionPadding: '10',
                iconBottomPadding: '10',
            }),
        })

        createInstanceForm.reset()
        setIsButtonDisabled(false)
    }
    return (
        <>
            <Form {...createInstanceForm}>
                <form onSubmit={createInstanceForm.handleSubmit(onSubmit)} className='flex w-full flex-col gap-5'>
                    <FormField
                        control={createInstanceForm.control}
                        name='name'
                        render={({ field }: { field: any }) => (
                            <FormItem>
                                <FormLabel className='text-base font-light'>Name</FormLabel>
                                <FormControl>
                                    <Input
                                        className='border-zinc-300'
                                        disabled={isButtonDisabled}
                                        placeholder='VistaChat'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    <Button disabled={isButtonDisabled} type='submit' className='w-full rounded-full text-sm'>
                        <LoadingIcon loading={isButtonDisabled} className='mr-2'/>
                        Create project
                    </Button>
                </form>
            </Form>
        </>
    )
}
export default CreateInstance