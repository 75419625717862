import { dotenv, ENV } from 'config/dotenv.config'
import { FirebaseFactory } from 'config/firebase.factory'
import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { ChatType } from 'modules/chat/types/chat.type'
import { InstanceType } from 'modules/instances/types/instance.type'
import { BannedUserType, UserType } from 'modules/user/types/banned-user.type'
import { NODE_ENV } from 'utils/NODE_ENV'
import { UserRepository } from '../modules/user/repository'

const firebaseConfig = {
    apiKey: dotenv.get(ENV.FIRE_API_KEY),
    authDomain: dotenv.get(ENV.FIRE_AUTH_DOMAIN),
    projectId: dotenv.get(ENV.FIRE_PROJECT_ID),
    storageBucket: dotenv.get(ENV.FIRE_STORAGE_BUCKET),
    messagingSenderId: dotenv.get(ENV.FIRE_MESSAGING_SENDER_ID),
    appId: dotenv.get(ENV.FIRE_APP_ID),
}

export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseFirestore = getFirestore(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)
export const firebaseProvider = new GoogleAuthProvider()

export const firebaseCollections = {
    chats: `${NODE_ENV}_chats`,
    bannedUsers: `${NODE_ENV}_banned_users`,
    users: `${NODE_ENV}_users`,
    instances: `${NODE_ENV}_instances`,
}

export const firebaseStorageDirectories = {
    chatMedia: 'chat_media',
    userMedia: 'user_media',
}

export const dbBannedUsers = new FirebaseFactory<BannedUserType>(firebaseFirestore, firebaseCollections.bannedUsers)
export const dbChats = new FirebaseFactory<ChatType>(firebaseFirestore, firebaseCollections.chats)
export const dbInstances = new FirebaseFactory<InstanceType>(firebaseFirestore, firebaseCollections.instances)
export const dbUsers = new FirebaseFactory<UserType>(firebaseFirestore, firebaseCollections.users)
export const userRepo = new UserRepository(firebaseFirestore, firebaseCollections.users)

type UploadImageToStorage = (directory: string, file: File, name: string) => Promise<string>
export const uploadImageToStorage: UploadImageToStorage = async (directory, file, name) => {
    const imageRef = ref(firebaseStorage, `${directory}/${name}`)
    await uploadBytes(imageRef, file)
    return getDownloadURL(imageRef)
}

type DeleteImageFromStorage = (refString: string) => Promise<void>
export const deleteImageFromStorage: DeleteImageFromStorage = async refString => {
    try {
        const fileRef = ref(firebaseStorage, refString)
        return deleteObject(fileRef)
    } catch (error) {
        console.log(error)
        return
    }
}
