import { Button } from 'components/ui/button'
import { urlConfig } from 'config/url.config.ts'
import AdminAvatar from 'modules/admin/components/admin-avatar'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { useThemeStore } from 'modules/theme/store/theme.store'
import { getSystemTheme } from 'modules/theme/utils/get-system-theme'
import { UserRole } from 'modules/user/types/schemas/user.schema'
import { forwardRef, memo, useEffect, useState } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'
import { UserType } from '../modules/user/types/banned-user.type'

interface NavbarProps {}
export const Navbar = memo(
    forwardRef<React.ComponentRef<'div'>, NavbarProps>(({ ...props }, ref) => {
        const theme = useThemeStore(state => state.theme)
        const isAuth = useIsAuthenticated()
        const auth: UserType = useAuthUser()
        const path = useLocation().pathname
        const [activePage, setActivePage] = useState<string>('websites')
        const navigate = useNavigate()

        const instances = useInstanceStore(state => state.instances)
        const setInstanceId = useInstanceStore(state => state.setInstanceId)
        const instanceId = useInstanceStore(state => state.instanceId)

        useEffect(() => {
            setActivePage(window.location.pathname.replace('/', ''))
        }, [])

        if (path.includes('/client')) {
            return
        }

        const handleCLick = (value: string) => {
            setActivePage(value)
        }

        return (
            <div
                ref={ref}
                {...props}
                className='bg-background/90 mb-3 w-full whitespace-nowrap bg-white font-serif backdrop-blur-sm'
            >
                <div className='flex w-full flex-col items-center justify-between gap-1 bg-none px-10  sm:flex-row'>
                    <div className='flex items-center gap-2'>
                        <Link
                            to={isAuth ? urlConfig.pages.main : 'https://vistachat.com'}
                            className='cursor-pointer text-lg font-bold'
                        >
                            <NavbarLogo theme={theme}/>
                        </Link>
                    </div>

                    {isAuth && (
                        <div className='flex h-full flex-wrap items-center justify-center gap-7'>
                            <Link
                                to={urlConfig.pages.main}
                                onClick={() => handleCLick('websites')}
                                className={cn(
                                    activePage === 'websites'
                                        ? 'border-b-4 border-blue-600 text-blue-600'
                                        : 'hover:text-blue-600 hover:border-b-4 hover:border-blue-600 ',
                                    'h-full cursor-pointer font-semibold uppercase',
                                    'transition-all duration-150',
                                )}
                            >
                                Websites
                            </Link>
                            {(auth?.role === UserRole.ADMIN || auth?.role === UserRole.SUPERADMIN) && (
                                <>
                                    <Link
                                        to={urlConfig.pages.statistics}
                                        onClick={() => handleCLick('statistics')}
                                        className={cn(
                                            activePage === 'statistics'
                                                ? 'border-b-4 border-blue-600 text-blue-600'
                                                : 'hover:text-blue-600 hover:border-b-4 hover:border-blue-600 ',
                                            'h-full cursor-pointer font-semibold uppercase',
                                            'transition-all duration-150',
                                        )}
                                    >
                                        Statistics
                                    </Link>
                                    <Link
                                        to={urlConfig.pages.admin}
                                        onClick={() => handleCLick('admin')}
                                        className={cn(
                                            activePage === 'admin'
                                                ? 'border-b-4 border-blue-600 text-blue-600'
                                                : 'hover:text-blue-600 hover:border-b-4 hover:border-blue-600 ',
                                            'h-full cursor-pointer font-semibold uppercase',
                                            'transition-all duration-150',
                                        )}
                                    >
                                        Admin page
                                    </Link>
                                </>
                            )}
                        </div>
                    )}

                    <div className='flex items-center gap-5'>
                        {(path === '/login' || path === '/register' || path === '/reset' || path === '/all-login') && (
                            <>
                                <Link
                                    to='https://vistachat.com/#features'
                                    className='hidden cursor-pointer font-serif text-3xl sm:inline'
                                >
                                    Features
                                </Link>
                                <Link
                                    to='https://vistachat.com/#pricing'
                                    className='hidden cursor-pointer font-serif text-3xl sm:inline'
                                >
                                    Pricing
                                </Link>
                            </>
                        )}

                        {!isAuth && (
                            <Button
                                className={cn(
                                    'my-1 rounded-full border-2 border-zinc-900 bg-transparent text-xl font-bold uppercase',
                                    theme === 'dark'
                                        ? 'border-[#0B1E55] bg-[#030E31] transition-all hover:bg-slate-200 hover:text-slate-900'
                                        : 'hover:bg-transparent hover:text-blue-800',
                                )}
                                variant='secondary'
                                asChild
                            >
                                <Link to={urlConfig.pages.login}>Login</Link>
                            </Button>
                        )}

                        {isAuth && (
                            <Link
                                onClick={() => {
                                    setActivePage('profile')
                                }}
                                to={urlConfig.pages.profile}
                                className='size-10 my-1 cursor-pointer'
                            >
                                <AdminAvatar image={auth?.image} name={auth?.name}/>
                            </Link>
                        )}

                        {
                            /* {isAuth && (
                            <div className="flex gap-3">
                                {(auth?.role === UserRole.ADMIN || auth?.role === UserRole.SUPERADMIN) &&
                                    (path === '/statistics' ? (
                                        <Button
                                            onClick={() => navigate(urlConfig.pages.main)}
                                            variant="outline"
                                            className="flex cursor-pointer items-center gap-2"
                                        >
                                            <MessageSquareText className="size-5" />
                                            Chat
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => navigate(urlConfig.pages.statistics)}
                                            variant="outline"
                                            className="flex cursor-pointer items-center gap-2"
                                        >
                                            <BarChartHorizontalBig className="size-5" />
                                            Statistics
                                        </Button>
                                    ))}
                                {(auth?.role === 'admin' || auth?.role === 'superadmin') &&
                                    (path === '/admin' ? (
                                        <Button
                                            variant="outline"
                                            className="flex cursor-pointer items-center gap-2"
                                            onClick={() => navigate(urlConfig.pages.main)}
                                        >
                                            <MessageSquareText className="size-5" />
                                            Chat
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outline"
                                            className="flex cursor-pointer items-center gap-2"
                                            onClick={() => navigate(urlConfig.pages.admin)}
                                        >
                                            <UserCog className="size-5" />
                                            Admin Page
                                        </Button>
                                    ))}
                                <Link to={urlConfig.pages.profile} className="cursor-pointer size-10">
                                    <AdminAvatar image={auth?.image} name={auth?.name} />
                                </Link>
                            </div>
                        )} */
                        }
                        {/* <ThemeSelector /> */}
                        {/* <ChatSettings name={auth?.name} /> */}
                    </div>
                </div>
            </div>
        )
    }),
)
Navbar.displayName = 'Navbar'

const NavbarLogo = ({ theme }: { theme: string }) => {
    const themeColor = theme === 'light' ? 'black' : theme === 'system' ? (getSystemTheme() === 'light' ? 'black' : 'white') : 'white'
    return (
        <div className='flex items-center gap-2'>
            <span className='font-serif text-3xl font-medium'>VistaChat</span>
        </div>
    )
}
