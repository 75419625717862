import { UserRole } from './schemas/user.schema'

export interface BannedUserType {
    id: string
    //
    ip: string
    reason: string
    bannedBy: string
    bannedAt: string
}

export enum BannedUserFieldsEnum {
    IP = 'ip',
}

export interface UserType {
    id: string
    //
    email: string
    image?: string
    name: string
    password?: string
    registered_date?: string
    registered_ip?: string
    registered_location?: string
    role: UserRole
    userOwnerId: string
}
