import { dotenv, ENV } from 'config/dotenv.config'
import { doc, Firestore, setDoc } from 'firebase/firestore'

export class UserRepository {
    #db
    #collection

    constructor(db: Firestore, collection: string) {
        this.#db = db
        this.#collection = collection
    }

    create = (uid, data) => setDoc(doc(this.#db, this.#collection, uid), data)

    exists = (id) => fetch(`${dotenv.get(ENV.API_URL)}/user/${id}`).then(res => res.json())
}