import { firebaseCollections, firebaseFirestore } from 'config/firebase.config'
import { urlConfig } from 'config/url.config'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useChatStore } from 'modules/chat/store/chat.store'
import { ChatType, ChatTypeFieldsEnum } from 'modules/chat/types/chat.type'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { Sidebar } from 'modules/sidebar/components/sidebar'
import { memo, useEffect } from 'react'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { UserType } from '../../user/types/banned-user.type'

interface ChatLayoutProps {
    step: string
    setStep: (step: string) => void
}
export const ChatLayout: React.FC<ChatLayoutProps> = memo(({ step, setStep }) => {
    const { id } = useParams()

    const path = window.location.pathname

    const auth: UserType = useAuthUser()

    const fetchChats = useChatStore(state => state.fetchChats)
    const setAllChats = useChatStore(state => state.setAllChats)
    const fetchInstances = useInstanceStore(state => state.fetchInstances)
    const instanceId = useInstanceStore(state => state.instanceId)
    const instances = useInstanceStore(state => state.instances)
    const navigate = useNavigate()
    const setInstanceId = useInstanceStore(state => state.setInstanceId)

    // initial chats fetch
    useEffect(() => {
        fetchInstances(auth?.userOwnerId)
    }, [auth?.id, auth?.userOwnerId, fetchInstances])

    useEffect(() => {
        fetchChats(instanceId)
    }, [fetchChats, instanceId])

    useEffect(() => {
        if (instances.length > 0 && !instanceId && !path.includes('/client')) {
            navigate(urlConfig.pages.instance.replace(':id', instances[0]?.id || ''), { replace: true })
            setInstanceId(instances[0]?.id || '')
        }
    }, [instances, instanceId, navigate, setInstanceId, path])

    // subscribe to chats changes
    useEffect(() => {
        const idToFetch = path.includes('instance') ? id || instanceId : instanceId

        const q = query(
            collection(firebaseFirestore, firebaseCollections.chats),
            where(ChatTypeFieldsEnum.IS_ARCHIVED, '==', false),
            where(ChatTypeFieldsEnum.IS_BANNED, '==', false),
            where(ChatTypeFieldsEnum.INSTANCE_ID, '==', idToFetch),
        )
        const unsub = onSnapshot(q, snapshot => {
            const chats = snapshot.docs.map(
                doc => ({
                    ...doc.data(),
                    id: doc.id,
                } as ChatType),
            )

            setAllChats(chats)
        })
        return () => {
            unsub()
        }
    }, [id, instanceId, path, setAllChats])

    return (
        <div className='flex h-full gap-5 px-10'>
            {instances.length > 0 && <Sidebar className='w-80' step={step} setStep={setStep}/>}
            <Outlet/>
        </div>
    )
})
ChatLayout.displayName = ChatLayout.name